import Image from "next/image";
import Link from "next/link";

import { PartialPartner } from "@/lib/partner/types";
import { partnerImageDimensions } from "@/constants/hero/imageDimensions";

type PartnerLogoProps = {
  title: string;
  partners: PartialPartner[];
};

const PartnerLogo = ({ title, partners }: PartnerLogoProps) => {
  const partner = partners[0];
  return (
    <div className="bg-putty-100 border rounded-2xl shadow w-full max-w-md mx-auto bg-white p-4">
      <div className="flex justify-center">
        {partner.showOnSchoolsAndPartnersPage ? (
          <Link
            className="d-inline-block"
            key={partner.key}
            href={`/school/${partner.slug}`}
          >
            <Image
              src={partner.logoUrl || ""}
              width={partnerImageDimensions.width}
              height={partnerImageDimensions.height}
              alt={title || ""}
              title={title || ""}
              key={partner.key}
              className="max-w-[165px] md:max-w-full"
            />
          </Link>
        ) : (
          <Image
            src={partner.logoUrl || ""}
            width={partnerImageDimensions.width}
            height={partnerImageDimensions.height}
            alt={title || ""}
            title={title || ""}
            key={partner.key}
            className="max-w-[165px] md:max-w-full"
          />
        )}
      </div>
    </div>
  );
};

export default PartnerLogo;
