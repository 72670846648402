type IndustryInsightsProps = {
  insights: { value: string }[];
};

export default function IndustryInsights({ insights }: IndustryInsightsProps) {
  const insight = insights.at(0)?.value ?? "";
  const sourceRegex = /\(Source:.*?\)/gi;
  const parts = insight.split(sourceRegex);
  const sourceMatch = insight.match(sourceRegex);

  return (
    <div className="bg-putty-100 border rounded-2xl shadow w-full max-w-md mx-auto bg-white p-4">
      <ul className="list-disc pl-5 text-base font-bold">
        <li className={"mb-2"}>
          {" "}
          {parts[0]}
          {sourceMatch && (
            <span className="font-normal"> {sourceMatch[0]}</span>
          )}
          {parts[1] && <span className="font-normal">{parts[1]}</span>}
        </li>
      </ul>
    </div>
  );
}
