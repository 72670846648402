import { useState } from "react";
import type { ProcessedCourse } from "@/lib/course/types";

type ProgramCoursesProps = {
  courses: ProcessedCourse[];
};

export default function ProgramCourses({ courses }: ProgramCoursesProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-putty-100 border rounded-2xl shadow w-full max-w-md mx-auto bg-white">
      <h2 className="text-base font-semibold text-white bg-[#1f453d] rounded-t-2xl py-2 px-4 my-0">
        There are {courses.length} courses in this program
      </h2>
      <div className="p-4">
        <ul className="list-disc pl-5 text-base font-bold">
          {courses.map((course, index) => (
            <li
              key={index}
              className={`mb-2
            ${!isExpanded && index > 1 ? "hidden" : ""}`}
            >
              {course.productName}
            </li>
          ))}
        </ul>

        {courses.length > 2 && (
          <div
            className={`
            flex
            ${courses.length <= 2 ? "hidden" : ""} 
          `}
          >
            <span
              className="underline cursor-pointer"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {!isExpanded ? "Show More" : "Show Less"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
